.featureContainer {
  min-height: 800px;
  // width: 100%;
  justify-items: center;
  margin-top: 3rem;;
}

.featureItemContainer {
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media  screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
    margin: auto;
    width:100%;
  }
  @media screen and (min-width: 1572px){
    width: 75%;
  }
}

.featureItem {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 1rem 2rem;
  h2{
    font-size: 24px
  }
}
.checkImg {
    min-width: 48px;
      width: 48px;
  align-self: center;
}

.title {
  text-transform: uppercase;
  margin: 0;
  font-family: sans-serif;
  text-align: center;
  font-size: 3rem;
  letter-spacing: 0.2rem;
  font-weight: normal;
}

.companyName {
  font-weight: bold;
  letter-spacing: 0.4rem;
  text-decoration: underline;
}

.firstBreak {
  display: none;
  @media screen and (max-width: 515px) {
    display: block;
  }
}
.secBreak {
  display: none;
  @media screen and (max-width: 898px) {
    display: block;
  }
}

.featuresDesc {
  font-size: 1.5rem;
  padding: 2rem 4rem;

  @media screen and (min-width: 768px) {
    padding: 2rem;    
    text-align: center;
  }
}
.pricingText{
  font-style: italic;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 768px) {
      width: 50%
    }
}
