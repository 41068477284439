.mobileBtn {
  all: unset;
  position: relative;
  width: 2.5rem;
  margin-top: 0.5rem
}

.mobileMenu {
  height: fit-content;
  width: 85vw;
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid rgb(218, 171, 171);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  z-index: 2;
  background-color: white;

  @media screen and (min-width: 425px) {
    width: 375px;
    right: 10px;
    left: unset;
    margin: unset;
  }
}

.mobileAnchorList {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  gap: 1.25rem;
  padding: 0;
}
.mobileListItem {
  text-decoration: none;
  list-style-type: none;
  padding: 0.75rem;

  &:not(:last-child) {
    border-bottom: solid 1px rgb(203, 203, 203);
  }
  > a:hover {
    transform: scale(1.10);
    text-decoration: underline;
    text-underline-offset: 4px;
    ;
    cursor: pointer;
  }

  >a:active {
    transform: translateY(3px);
  }
}
