.contactContainer {
  // height: 500px;
  margin-top: 2rem;
  max-height: fit-content;
  width: 100%;
  // background-color: azure;
  padding: 2rem 2rem;
  position: relative;
font-family: sans-serif;
}

.title {
  margin: 0;
}

.contactText {
  align-self: center;
  width:80%;
  @media screen and (min-width: 768px) {
    width: 40%;
    align-self: normal;
    margin-left: 4%
  }
    @media screen and (min-width: 1572px) {
      width:630px;

    }
}

.contactBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  // justify-content: space-evenly;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  justify-content: center;
    gap: 3rem;
  }
}

.contactForm {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: 1.25rem;

  >  div:not(:first-child) {
    margin-top:1rem;
  }

  button {
    margin-top: 2rem;
    width: 80%
  }

  label:not(:first-child) {
    margin-top: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    width: 40%;
    margin-right: 10%;
    max-width: 630px;
  }
}

.submitButton{
  text-transform: uppercase;
}

.infoEmail{
  display: none;
  @media screen and (min-width: 768px) {
      display: block;
      color: #6f6f6f;
    }
  .email{
    color:black;
  }
}

.infoEmailMobile {
  display: none;
  padding:2rem;


  @media screen and (max-width: 767px) {
    display: block;
    color: #6f6f6f;
  }

  .emailMobile {
    color: black;
  }
}
