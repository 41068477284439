.footerContainer {
  height: 60px;
  width: 100%;
  background-color: white;
  padding: 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0;
}
