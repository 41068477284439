.servicesContainer {
  background-color: #e1e1e1;
  width: 100%;
  padding: 2rem 1rem;
  
}

.title {
  margin: 0;
  font-family: sans-serif;
  text-align: center;
  font-size: 3rem;
  letter-spacing: 0.25rem;
}

.servicesCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  flex-wrap: wrap;
  gap: 3rem;
}

.accordion{
  width: 100%;

  .accordionHeading{
    font-size: 26px;
    letter-spacing: 1.6px;

    > div:nth-child(n+1){
      font-weight: 600 !important;
    }
  }
  .accordionPanelText{
    font-size: 18px;
      letter-spacing: 1.2px;
  }
}
