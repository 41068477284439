.anchorList {
  display: grid;
  grid-auto-flow: column;
  margin-left: 1rem;
  gap: 0.75rem;

  @media screen and (min-width: 768px){
  width: 85%;
  gap: 1.25rem;
  justify-content: flex-end;
  }

  @media screen and (min-width: 1024px) {
    gap: 1.5rem;
  }
  @media screen and (min-width: 1440px) {
    gap: 2rem;
    font-size: 1.125rem;
  }
}

.listItem {
  text-decoration: none;
  list-style-type: none;
  &:hover{
    transform: scale(1.10);
    text-decoration: underline;
    text-underline-offset: 4px;;
    cursor: pointer;
  }
  &:active{
    transform: translateY(3px);
  }
}
