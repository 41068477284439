.heroContainer {
  padding: 1rem;
  width: 100%;
  margin-top: 69px;
  background-position: top;
  background-size: cover;
  background-image: url('../../assets/images/hero.png');

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    align-items: unset;
    padding: 2rem;
    padding-bottom: 4rem;
  }
  @media screen and (min-width: 1224px){
    min-height: 800px;
  }
    @media screen and (min-width: 1650px) {
      min-height: 900px;
    }
}

.heroContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  justify-content: space-evenly;
  height: 100%;
  @media screen and (min-width: 768px) {
    width: 61%;
  }
}

.title {
  font-family: sans-serif;
  letter-spacing: 0.25rem;
  font-size: 48px;
  text-transform: uppercase;

  @media screen and (min-width: 425px) {
    font-size: 55px;
  }

  @media screen and (min-width: 768px) {
    font-size: 78px;
  }
}

.description {
  line-height: 1.5;
  font-size: 29px;
  white-space: wrap;
}

.ctaButton {
  bottom: -35px;
  width: min-content;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  }
}

.chevronIcon {
  animation: shake 4.5s linear 5s infinite;
  // animation-delay: 5s;
}
@keyframes shake {
  0% {
    transform: skewY(-21deg);
  }

  2% {
    transform: skewY(21deg);
  }

  4% {
    transform: skewY(-21deg);
  }

  6% {
    transform: skewY(21deg);
  }

  8% {
    transform: skewY(-21deg);
  }
  10% {
    transform: skewY(21deg);
  }
  12% {
    transform: skewY(0deg);
  }

  100% {
    transform: skewY(0deg);
  }
}
