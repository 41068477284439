.headerContainer {
  width: 100%;
  height: 69px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-color: rgba(231, 231, 231, 0);
  padding: 15px 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
    position: fixed;
      top: 0;
      z-index: 1;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
}

.leftIcon {
  width: 3rem;
}
